<template>
  <div class="KCmodule">
    <Header back="返回" title="工程专利" index="首页" titleOne="科创模块" titleTwo="工程专利" beforeTitle="快捷上传" />
    <div class="content">
      <div class="topUser_s">
        <div class="top_up">
          <el-autocomplete
            class="inline-input"
            v-model="company_name"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            placeholder="请输入名称关键字筛选"
          ></el-autocomplete>
          <el-button class="search" @click="handleSearch()">查询</el-button>
          <div>
            <el-button class="look_up" @click="fileLookUp()">附件识别上传</el-button>
            <span style="color:#A6ABC7">（上传文件格式支持，压缩包zip和rar，点击按钮可进入“附件识别上传”界面）</span>
          </div>
        </div>
        <div class="clear"></div>
        <h1>快捷上传说明</h1>
        <h2>
          请在上方搜索框内进行查询，然后依次上传对应的附件即可。
          <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
        </h2>
      </div>
      <el-table :data="quick.data" style="width: 100%">
        <el-table-column prop="patent_name" label="专利名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.patent_name ? scope.row.patent_name : "-" }}</template>
        </el-table-column>
        <el-table-column prop="app_code" label="专利申请号" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.app_code ? scope.row.app_code : "-" }}</template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="270px">
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="UploadFile(scope.row.id)">上传</el-button>
            <el-button class="edit" size="mini" @click="UploadLook(scope.row.id)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="quickParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="quickParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="quickTotal"
        ></el-pagination>
      </div>
      <el-dialog
        title="上传"
        :visible.sync="dialogFormVisible"
        width="300px"
        height="400px"
        top="19%"
      >
        <el-form :model="Uploadform" :label-position="labelPosition">
          <el-select v-model="Uploadform.type" placeholder="请选择专利状态" @change="changeType">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in options"
              :key="item.value"
            ></el-option>
          </el-select>
          <el-upload
            class="upload-demo"
            ref="imgupload"
            action="string"
            :http-request="httpRequest"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :file-list="fileList"
          >
            <el-button type="primary" class="ChooseFile">请上传附件</el-button>
          </el-upload>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      labelPosition: "top",
      dialogFormVisible: false,
      typeId: "1",
      options: [
        {
          value: "0",
          label: "默认状态",
        },
        {
          value: "1",
          label: "新申请",
        },
        {
          value: "2",
          label: "审查中",
        },
        {
          value: "3",
          label: "授权",
        },
        {
          value: "4",
          label: "撤回",
        },
        {
          value: "5",
          label: "驳回",
        },
        {
          value: "6",
          label: "专利权维持",
        },
        {
          value: "7",
          label: "专利权失效",
        },
      ],
      quickTotal: 0,
      quickParams: {
        page: 1,
        limit: 10,
        com_id: "",
        order: "id desc",
      },
      company_name: "",
      code: "",
      fileList: [],
      Uploadform: {
        type: "0",
      },
    };
  },
  computed: {
    ...mapState(["quick", ["patent"]]),
  },
  created () {
    this.$store.dispatch("getquickList", this.quickParams);
    setTimeout(() => {
      this.quickTotal = this.$store.state.quick.total;
    }, 1000);
  },
  methods: {
    fileLookUp () {
      this.$router.push('/patent/quick/file_look_upload')
    },
    UploadLook (id) {
      this.$router.push({ path: "/patent/annex", query: { id: id } });
    },
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item);
      this.quickParams.com_id = item.id;
      this.company_name = item.value;
    },
    UploadFile (id) {
      this.meber_id = id;
      this.dialogFormVisible = true;
    },
    handleSizeChange (val) {
      this.quickParams.limit = val;
      this.$store.dispatch("getquickList", this.quickParams);
    },
    handleCurrentChange (val) {
      this.quickParams.page = val;
      this.$store.dispatch("getquickList", this.quickParams);
    },
    handleSearch () {
      this.$store.dispatch("getquickList", this.quickParams);
      setTimeout(() => {
        this.quickTotal = this.$store.state.quick.total;
      }, 1000);
    },
    changeType (val) {
      console.log(val);
      this.typeId = val;
    },
    httpRequest (param) {
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      form.append("status", this.typeId);
      form.append("patent_id", this.meber_id);
      this.upload("/api/patent/quick_upload", form).then((res) => {
        this.code = res.code;
        console.log(res);
        this.dialogFormVisible = false;
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "上传附件成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "上传附件失败",
          });
        }
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
  },
};
</script>

<style scoped>
.KCmodule .import {
  float: right;
  background: #edeef4;
  color: #909399;
  border: none;
}

.KCmodule .upload {
  float: right;
  background: #edeef4;
  color: #909399;
  border: none;
}

::v-deep .el-tabs__content {
  top: 20px !important;
}

::v-deep .topUser_s .el-input {
  width: 150px !important;
}

.topUser_s h1 {
  border-top: 1px solid #edeef4;
  padding-top: 20px;
  margin-top: 20px;
}

.topUser_s h2 {
  font-size: 14px;
  font-weight: 500;
}

.topUser_s h2 span {
  color: #a6abc7;
}
.upload-demo button {
  width: 220px;
  height: 40px;
  background: #edeef4;
  color: #909399;
  border: none;
  margin: 20px 0;
}
.el-autocomplete {
  display: inline-block;
  width: 200px;
  margin-right: 20px;
}
::v-deep.topUser_s .el-input {
  width: 200px !important;
}
.top_up {
  display: flex;
  align-items: center;
}
.look_up {
  background: #edeef4;
  border: none;
  color: #909399;
  width: 370px;
  margin: 0 20px;
}
</style>
